<template>
    <Layout>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">
                    <b-button v-b-tooltip.hover.top="$t('general.back')" class="me-2" size="sm" variant="outline-primary" @click="$router.go(-1)">
                        <i class="fas fa-long-arrow-alt-left"></i>
                    </b-button>
                    {{ $t('contact.modify', { id: model.m_id }) }}
                </h4>
            </div>
            <div class="card-body">
                <contact-form></contact-form>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <b-row>
                    <b-col cols="12">
                        <div class="d-flex align-items-center justify-content-between">
                            <h6 class="mb-0">{{ $t('contact.address') }}</h6>
                            <div>
                                <input ref="file" type="file" @change="upload" class="d-none" />
                                <b-button-group class="ms-2" >

                                    <b-button variant="outline-primary" size="sm" :title="$t('contact.import')" @click="$refs.file.click()">
                                        <i class="fas fa-file-import"></i>
                                    </b-button>
                                    <b-button href="/download/sample.xlsx" variant="outline-dark" size="sm" :title="$t('contact.download_sample')">
                                        <i class="fas fa-file-excel"></i>
                                    </b-button>
                                </b-button-group>
                                <b-button-group class="ms-2" >
                                    <b-button variant="outline-primary" size="sm" :title="$t('contact.export')">
                                        <i class="fas fa-file-export"></i>
                                    </b-button>
                                </b-button-group>
                                <b-button class="ms-2" variant="success" size="sm" @click="modal = true;">
                                    <i class="fas fa-user-plus me-2"></i>
                                    {{ $t('contact.add') }}
                                </b-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="card-body">
                <b-row>
                    <b-col cols="12">
                        <b-table
                            :caption="$t('contact.address')"
                            :items="content"
                            :fields="fields"
                            responsive="sm"
                            per-page="0"
                            hover
                            head-variant="light"
                            show-empty
                            tfoot-class="pt-2"
                            :empty-text="$t('contact.empty_address')"
                            :busy="loading"
                        >
                            <template #table-busy>
                                <div class="text-center">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">{{ $t('table.loading') }}...</span>
                                    </div>
                                </div>
                            </template>
                            <template #cell(actions)="data">
                                <b-button size="sm" v-b-tooltip.hover.top="$t('table.edit')" variant="dark" class="me-1" :to="$t('routes.address_edit').substr(0, $t('routes.address_edit').indexOf(':')) + data.item.m_id">
                                    <i class="fas fa-pencil-alt"></i>
                                </b-button>
                                <b-button size="sm" v-b-tooltip.hover.top="$t('table.delete')" variant="danger" @click="destroy(data.item.m_id)">
                                    <i class="fas fa-trash"></i>
                                </b-button>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
            </div>
        </div>
        <b-modal ref="contact-address-modal" centered body-bg-variant="light" v-model="modal">
            <template #modal-title>
                {{ $t('contact.add_address') }}
            </template>
            <address-form ref="address" modal></address-form>
            <template #modal-footer>
                <b-button size="sm" @click="$refs['contact-address-modal'].hide()">{{ $t('contact.close') }}</b-button>
                <b-button size="sm" variant="success" @click="$refs.address.save().then(() => $refs['contact-address-modal'].hide())">
                    <i class="fas fa-user-plus me-2"></i>
                    {{ $t('contact.add') }}
                </b-button>
            </template>
        </b-modal>
    </Layout>
</template>

<script>
import Layout from "../../layouts/table";
import {mapGetters} from "vuex";
import AddressForm from "@/views/pages/contact/address.form";
import ContactForm from "@/views/pages/contact/form";

export default {
    name: "ApiAccountForm",

    components: {
        Layout,
        AddressForm,
        ContactForm
    },

    computed: {
        ...mapGetters({
            list: 'contact/list',
            model: 'contact/item',
            content: 'contact/address/list',
            loading: 'contact/address/loading'
        }),

        fields: function () {
            return [
                {
                    key: "name",
                    label: this.$t('contact.name'),
                    thClass: 'gridjs-th',
                    sortable: true
                },
                {
                    key: "phone_number",
                    label: this.$t('contact.phone'),
                    thClass: 'gridjs-th',
                    sortable: true
                },
                {
                    key: "actions",
                    label: '',
                    thClass: 'gridjs-th',
                    tdClass: 'text-end'
                },
            ];
        },
    },

    data() {
        return {
            modal: false,
            file: null,
        };
    },

    methods: {
        upload: function (event) {
            this.file = event?.target?.files?.[0]
            this.confirmImport()
        },

        confirmImport() {
            this.$bvModal.msgBoxConfirm('Are you sure?')
                .then(value => {
                    if (value === false) {
                        this.file = null
                    } else {
                        this.$store.dispatch('address/importList', this.file)
                    }
                    console.log(value);
                })
                .catch(err => {
                    // An error occurred
                    console.log(err)
                })
        },

        destroy: function (id) {
            const h = this.$createElement
            const titleText = this.$t('table.confirm');
            const title = h('h5', { class: ['modal-title', 'text-light'], domProps: { innerHTML: '<i class="fas fa-exclamation-triangle me-2"></i>' + titleText }});
            const okText = this.$t('table.yes');
            const okTitle = h('span', {domProps: { innerHTML: '<i class="fas fa-trash me-2"></i>' + okText }})

            this.$bvModal.msgBoxConfirm(this.$t('table.confirm_delete'), {
                title: [title],
                titleClass: 'text-light',
                headerBorderVariant: 'danger',
                size: 'sm',
                buttonSize: 'sm',
                headerBgVariant: 'danger',
                headerTextVariant: 'light',
                bodyBgVariant: 'danger',
                bodyTextVariant: 'light',
                bodyClass: 'px-3 pb-3 pt-0',
                okVariant: 'light text-danger',
                okTitle: [okTitle],
                cancelTitle: this.$t('table.no'),
                cancelVariant: 'success',
                footerBgVariant: 'danger',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value === true) {
                        this.$store.dispatch('address/destroy', id);
                    }
                })
        },
    },

    created() {
        const action = this.list && this.list.length ? 'choose' : 'fetchOne';

        this.$store.dispatch(`contact/${action}`, this.$route.params.id).then(response => {
            document.title = this.setTitle(this.$t('contact.modify', { id: response.m_id }));
            this.$store.dispatch(`contact/address/fetchList`, response.m_id);
        });
    }
}
</script>

<style scoped>
input::placeholder {
    color: lightgrey;
}
</style>
